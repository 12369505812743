<template>
  <div class="betslip-input-wrapper" :class="[labelPositionClass, typeClass]">
    <label class="betslip-label">{{ label }}</label>
    <input class="betslip-input" :class="{ isInvalid: !isValid }"
           type="text"
           :id="id"
           @keydown="handleKeyDown"
           @keyup="handleKeyUp"
           @paste="handlePaste"
           @focus="handleFocus"
           @click="handleClick"
           @blur="handleBlur"
           @input="handleChange"
           @contextmenu="disableContextMenu"
           :value="value"
           :disabled="disabled"
           ref="input"
           inputmode="none"
           autocomplete="off" />
  </div>

</template>

<script>
import { isNil } from 'lodash';
import { inputUtils, eventBus } from '../../utility';
import { typeable } from '../../mixins/index';

export default {
  name: 'GamesBetslipInput',
  props: {
    label: {
      type: String,
      default: 'Stake',
    },
    labelPosition: {
      type: String,
      default: 'right',
    },
    isFromDispatchEvent: {
      type: Boolean,
      default: false,
    },
    isValid: {
      type: Boolean,
      default: true,
    },
    parentValue: {
      type: [String, Number],
      default: '',
    },
    disableRounding: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: 'input',
    },
  },
  emits: ['change', 'fieldFocus', 'click', 'validate'],
  mixins: [typeable],
  data() {
    return {
      isCursorBeforeDecimalPoint: false,
      isCursorBeforeFirstChar: false,
      isCursorBeforeZero: false,
      value: '0.00',
    };
  },
  computed: {
    labelPositionClass() {
      return this.labelPosition;
    },
  },
  methods: {
    disableContextMenu(event) {
      const e = event;
      e.returnValue = false;
      e.preventDefault();
    },
    formatNumber(number) {
      if (!this.disableRounding && number !== '') {
        return Number(number).toFixed(2);
      }

      return String(number);
    },
    handleKeyDown(event) {
      const { key } = event;

      // Focus management
      if (key === 'Enter') {
        this.$emit('validate', this.value);
        if (this.id === 'focusFirstBet' || this.id === 'input' || this.id === 'focusStakePerBet' || this.id === 'focusFutureRounds') {
          this.focusTotalPaymentById();
        }
      }

      if (key === 'Tab') {
        if (this.id === 'focusTotalPayment') {
          event.preventDefault();
          eventBus.$emit('ReturnFocusToParent');
          eventBus.$emit('focusFirstBettingInput');
        }

        if (this.id === 'focusFutureRounds') {
          this.$emit('validate', this.value);
        }
      }

      this.isCursorBeforeZero = inputUtils.checkIsCursorBeforeZero(
        event.target.selectionStart,
        this.value,
      );
      this.isCursorBeforeDecimalPoint = inputUtils.checkIsCursorBeforeDecimalPoint(
        event.target.selectionStart,
        this.value,
      );
      this.isCursorBeforeFirstChar = inputUtils.checkIsCursorBeforeFirstChar(
        event.target.selectionStart,
        this.value,
      );

      // Allow user to enter numbers when text is selected
      if (
        inputUtils.isValueSelected(this.value)
        && this.isInputValid(key, event, { isNumberLimitDisabled: true, isZeroRuleDisabled: true })
      ) {
        return;
      }

      if (this.isInputValid(key, event)) {
        if (this.isFromDispatchEvent && !inputUtils.ALLOWED_KEYS.includes(key)) {
          this.value = inputUtils.insertAtCursorPosition(
            this.value,
            key, event.target.selectionStart,
          );
        }
      } else {
        event.preventDefault();
      }
    },
    handleKeyUp() {
      if (inputUtils.valueIncludesDot(this.value)) {
        if (inputUtils.isDecimalLimitReached(this.value)) {
          // Trim decimals when we remove and place a dot somewhere else in number
          this.value = inputUtils.trimDecimalsOverLimit(this.value);

          if (inputUtils.valueIncludesComma(this.value)) {
            // Remove commas when user starts spamming
            this.value = inputUtils.removeCommas(this.value);
          }
        }
      }

      if (!inputUtils.valueIncludesDot(this.value) && inputUtils.isNumberLimitReached(this.value)) {
        this.value = inputUtils.trimValueToNumberLimit(this.value);
      }
    },
    handlePaste(event) {
      event.preventDefault();
    },
    handleFocus() {
      this.$emit('fieldFocus');
    },
    handleBlur() {
      // Don't apply zero values
      if (this.value === '' || this.value === '0.00') return;

      if (this.isFromDispatchEvent) {
        this.$emit('change', this.value);
      } else {
        this.$emit('change', this.formatNumber(this.value));
        eventBus.$emit('formatBetslipInputValue');
      }
    },
    handleChange(event) {
      this.value = event.target.value.replace(/[^,.\d]/g, '');
      // eslint-disable-next-line no-param-reassign
      event.target.value = event.target.value.replace(/[^,.\d]/g, '');

      // Replace comma with dot
      if (inputUtils.valueIncludesComma(this.value)) {
        this.value = inputUtils.replaceCommaWithDot(this.value);
      }

      // if (this.value === '' || this.value === '0.00') return;

      this.$emit('change', this.value);
    },
    emitZeroStake() {
      this.$root.$emit('setZeroStake');
    },
    handleClick(event) {
      event.target.select();
      this.$emit('click');
    },
    isInputValid(key, event, options) {
      const isNumberLimitDisabled = options?.isNumberLimitDisabled ?? false;
      const isZeroRuleDisabled = options?.isZeroRuleDisabled ?? false;

      if (!inputUtils.ALLOWED_CHARACTERS.test(key)) {
        if (
          !inputUtils.ALLOWED_KEYS.includes(key)
          && inputUtils.ALLOWED_KEY_CODE !== event.keyCode
        ) {
          // eslint-disable-next-line no-useless-return
          return false;
        }
      } else {
        // Prevent user from entering only dot or comma
        if (
          (inputUtils.valueIncludesComma(key) || inputUtils.valueIncludesDot(key))
            && this.value.length <= 0
        ) {
          return false;
        }

        // Prevent user from entering more than 1 decimal points
        if (inputUtils.isDotLimitReached(this.value, key)) {
          return false;
        }

        // Prevent user from entering more than 13 digits
        // when there is no decimal point
        if (
          inputUtils.isKeyDifferentFromDotAndComma(key)
          && !inputUtils.valueIncludesDot(this.value)
          && inputUtils.isNumberLimitReached(this.value)
        ) {
          return false;
        }

        // Prevent user from entering more than 13 digits
        // before decimal point and more than 2 digits after decimal point
        if (!isNumberLimitDisabled) {
          if (
            inputUtils.valueIncludesDot(this.value)
            && inputUtils.isNumberLimitReached(this.value)
            && inputUtils.isDecimalLimitReached(this.value)
          ) {
            return false;
          }
        }

        // Prevent user from entering more than 2 decimal numbers
        // But allow user to enter numbers before decimal point
        if (
          inputUtils.isDecimalLimitReached(this.value)
          && !this.isCursorBeforeDecimalPoint
        ) {
          return false;
        }

        // Prevent user from entering more than 1 zero
        // But allow user to enter numbers before zero
        if (
          inputUtils.isFirstCharZero(this.value)
          && inputUtils.isKeyDifferentFromDotAndComma(key)
          && !inputUtils.valueIncludesDot(this.value)
          && !this.isCursorBeforeZero
        ) {
          return false;
        }

        // Prevent user from entering numbers after zero
        if (
          inputUtils.isFirstCharZero(this.value)
          && this.isCursorBeforeDecimalPoint
          && !this.isCursorBeforeZero
        ) {
          return false;
        }


        if (!isZeroRuleDisabled) {
          // Prevent user from entering one more zero before existing zero
          if (
            inputUtils.isKeyZero(key)
            && this.isCursorBeforeFirstChar
            && !inputUtils.isFirstCharDot(this.value)
          ) {
            return false;
          }
        }
      }

      return true;
    },
    validateValue(value) {
      const stringValue = String(value);
      const [before, after] = stringValue.split('.');

      if (!isNil(after)) {
        if (after.length > 2) {
          const limitedAfter = after.slice(0, 2);

          return `${before}.${limitedAfter}`;
        }
      }

      return stringValue;
    },
    formatValueWithEventBus() {
      this.value = this.formatNumber(this.value);
    },
    focusTotalPaymentById() {
      const totalPayment = document.getElementById('focusTotalPayment');

      if (totalPayment) {
        totalPayment.focus();
        this.$nextTick(() => {
          totalPayment.select();
        });
      }
    },
  },
  watch: {
    parentValue(value) {
      if (value === '') {
        this.value = '';
        return;
      }

      this.value = this.validateValue(value);
    },
  },
  mounted() {
    this.value = this.formatNumber(this.validateValue(this.parentValue));

    eventBus.$on('formatBetslipInputValue', this.formatValueWithEventBus);
  },
  // Vue 2 vs Vue3 issue => beforeDestroy hook doesn't exist in vue3 it's changed to beforeUnmount
  beforeUnmount() {
    eventBus.$off('formatBetslipInputValue', this.formatValueWithEventBus);
  },
  beforeDestroy() {
    eventBus.$off('formatBetslipInputValue', this.formatValueWithEventBus);
  },
};
</script>

<style lang="scss" scoped>
.betslip-input-wrapper {
  width: 100%;
  position: relative;
  height: 40px;

  .betslip-label {
    position: absolute;
    top: 4px;
    color: var(--text-primary-2);
    font-size: 12px;
  }

  &.left.type-retail {
    .betslip-input {
      text-align: left;
      padding-left: 8px;
    }
  }

  &.type-retail {
    .betslip-input {
      background-color: #E5E5E5;
      color: #2C2C2C;
      border-radius: 0;
      min-width: 60px;
      width: 100%;
      display: flex;
      text-align: center;
    }
  }

  &.type-terminal {
    height: 54px;

    .betslip-input {
      font-family: 'Roboto', sans-serif ;
      height: 54px;
      width: 140px;
      font-size: 18px;
      font-weight: 400;
    }

    .betslip-label {
      font-size: 16px;
    }
  }

  &.right.type-terminal {
    .betslip-input {
      padding: 16px 8px 0px 0px;
      text-align: right;
    }

    .betslip-label {
      top: 6px;
      padding: 0 8px;
      width: 100%;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.left.type-terminal {
    .betslip-input {
      padding: 16px 0px 0px 8px;
      text-align: left;
    }

    .betslip-label {
      top: 6px;
      left: 8px;
    }
  }

  &.right.type-web {
    .betslip-input {
      padding: 16px 8px 0px 0px;
      text-align: right;
    }

    .betslip-label {
      right: 8px;
    }
  }

  &.left.type-web {
    .betslip-input {
      padding: 16px 0 0 8px;
    }

    .betslip-label {
      left: 8px;
    }
  }

  .betslip-input {
    bottom: 0;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 500;
    color: var(--text-primary-1);
    background-color: var(--input);
    border: 1px solid var(--input-border-color);
    width: 100%;
    height: 40px;
    //border: 0;
    border-radius: 2px;
    outline: none;

    &:disabled {
      background: var(--card-section);
    }

    &.isInvalid {
      border-bottom: 2px solid var(--system-negative);
    }
  }
}

@media only screen and (min-width: 1910px) {
  .betslip-input-wrapper {
    &.type-terminal {
      height: 72px;

      .betslip-input {
        font-size: 20px;
        font-weight: 500;
        height: 72px;
      }
    }

    &.type-terminal.right {
      .betslip-label {
        top: 8px;
        font-size: 20px;
      }

      .betslip-input {
        padding: 30px 8px 0px 0px;
      }
    }

    &.type-terminal.left {
      .betslip-label {
        top: 8px;
        font-size: 20px;
      }

      .betslip-input {
        padding: 30px 0px 0px 8px;
      }
    }
  }
}
</style>
