<template>
  <div class="helpWrapper" v-html="helpPage"></div>
</template>

<script>
export default {
  name: 'Help',
  props: {
    helpPage: String,
  },
};
</script>

<style lang="scss" scoped>
.helpWrapper {
  width: 100%;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>
